import { compressAvatar, uploadFile } from './file';
import { http, supabase_public } from './initSupabase';

export interface AccountParams {
  id: string;
  userName?: string;
  password?: string;
  avatar?: File;
  pinCode?: string;
  wallet?: string;
}

/**
 * update account
 * @param params AccountParams
 * @returns {status, msg, data}
 */
export const updateAccount = async (params: AccountParams) => {
  let avatar = null;

  // check params
  if (!params.id) {
    throw new Error('Missing user ID.');
  }

  // update password
  if (params.password) {
    const { error } = await supabase_public.auth.updateUser({
      password: params.password.trim()
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  // update username
  if (params.userName) {
    // check duplicate username
    const response = await http.instance
      .get(`/checkUserNameExist?userName=${params.userName.trim()}`)
      .catch(function (error: { message: any }) {
        throw new Error(error.message);
      });
    if (response.data.data.isExist) {
      throw new Error('Username already exists.');
    }

    const { error } = await supabase_public.auth.updateUser({
      data: { user_name: params.userName.trim() }
    });
    if (error) {
      throw new Error(error.message);
    }

    const result = await supabase_public
      .from('profiles')
      .update({ user_name: params.userName.trim() })
      .eq('user_id', params.id);
    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  // update pin code
  if (params.pinCode) {
    const { error } = await supabase_public.auth.updateUser({
      data: { pin_code: params.pinCode.trim() }
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  // update wallet
  if (params.wallet) {
    const { error } = await supabase_public.auth.updateUser({
      data: { user_wallet: params.wallet.trim() }
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  // update avatar
  if (params.avatar) {
    const ext = params.avatar.name.split('.').pop();
    if (!ext) {
      throw new Error('File missing suffix.');
    }

    const file = await compressAvatar(params.avatar);

    // upload image
    const filePath = params.id + '.' + ext;
    const url = await uploadFile('avatars', params.id + '/' + filePath, file!);
    avatar = url;

    // update user profile avatar
    const { error } = await supabase_public.auth.updateUser({
      data: { avatar: avatar }
    });
    if (error) {
      throw new Error(error.message);
    }

    const result = await supabase_public
      .from('profiles')
      .update({ avatar: avatar })
      .eq('user_id', params.id);
    if (result.error) {
      throw new Error(result.error.message);
    }
  }
  const {
    data: { user },
    error: user_error
  } = await supabase_public.auth.getUser();
  if (user_error) {
    throw new Error(user_error.message);
  }
  return user;
  /*   {
    status: 200,
    msg: 'Successfully updated account information.',
    data: { avatarURL: avatar }
  }; */
};

/**
 * retrieve a user
 * @returns {status, msg, data}
 */
export const retrieveUser = async () => {
  const {
    data: { user },
    error
  } = await supabase_public.auth.getUser();
  if (error) {
    throw new Error(error.message);
  }
  if (!user) {
    throw new Error('No User Found');
  }
  return user;
};
