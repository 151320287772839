import { Link } from "react-router-dom";
import style from "../style/Footer.module.css";
// import avatar from "../image/upcoming/↳ Avatar.png";
import { User } from "@supabase/supabase-js";
import { selectUser } from "db/schema/user";
import { useLocalStorage } from "hooks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import { handleAvatar } from "utils/avatar";
import { useStore } from '../store';
import { localGet } from "../utils/localStorage";
import SVGIcon from './SvgComponent';
import { IonFooter } from '@ionic/react';

const Footer: React.FC = () => {
  const { changeModalState, closeAllModalStates, globalUserInfo } = useStore()
  const [localStorageUserInfo] = useLocalStorage<User>('user_info');
  const userInfo = localStorageUserInfo ?? globalUserInfo;

  const location = useLocation();
  // const { user, isAuthenticated } = useAuth0()
  const history = useHistory();
  useEffect(() => {
    window.localStorage.setItem('link', location.pathname)
  })
  const [avatar, setAvatar] = useState<string>()
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (userInfo) {
      (async () => {
        const res = await selectUser()
        setAvatar(handleAvatar(res?._data?.avatarURL))
        // setAvatar(userInfo?.user_metadata?.avatar)
      })()
    }
    PubSub.subscribe('syncData', function (msg, searchName) {
      if (searchName.user) {
        setAvatar(handleAvatar(searchName?.user?.avatarURL));
        if (!localGet('chatProps')) {
        } else {
          if (searchName?.user?.avatarURL !== localGet('chatProps')[0]) {
            selectUser().then((res) => {
              setAvatar(handleAvatar(res._data?.avatarURL));
            })
          }
        }
      }
    })
  }, [userInfo, globalUserInfo])

  const handleCheckLogin = (event: any, url: string) => {
    event.preventDefault();
    closeAllModalStates(false);
    if (userInfo) {
      history.push(url);
    } else {
      changeModalState('isSignInModalOpen', true)
    }
  }
  return (
    <IonFooter mode="ios" style={{zIndex: 21075}}>
    <footer className={style.footerNav}
      style={
        location.pathname === "/"
          ||
          location.pathname === "/upcoming"
          || location.pathname === "/friends"
          || location.pathname === "/billion"
          || location.pathname === "/chatroom"
          || location.pathname === "/joinedgames"
          || location.pathname === "/pastgames"
          || location.pathname === "/hightolow"
          || location.pathname === "/lowtohight"
          || location.pathname === "/account"
          ? {} : { display: 'none' }}>
      <ul>
        <li className={
          location.pathname === "/"
            || location.pathname === "/upcoming"
            || location.pathname === "/joinedgames"
            || location.pathname === "/pastgames" ? style.nowTarget : ""} >
          <Link to="/upcoming" onClick={() => closeAllModalStates(false)}>
            <>
              <SVGIcon
                name="FooterTopBorderSvg"
                width="32"
                height="3"
                viewBox="0 0 32 3"
                className={style.isNow}
              />
              <SVGIcon
                name="FooterUpComingSvg"
                className={style.a}
                width="20"
                height="16"
                viewBox="0 0 20 16"
              />
            </>
          </Link>
        </li>
        <li className={location.pathname === "/friends" ? style.nowTarget : ""} >
          <Link to='/friends' onClick={(event) => handleCheckLogin(event, '/friends')}>
            <>
              <SVGIcon
                name="FooterTopBorderSvg"
                width="32"
                height="3"
                viewBox="0 0 32 3"
                className={style.isNow}
              />
              <SVGIcon
                name="FooterFriendsSvg"
                width="20"
                height="14"
                viewBox="0 0 20 14"
                className={style.routerTarget}
              />
            </>
          </Link>
        </li>
        <li className={location.pathname === "/billion" ? style.crownACtive : style.crown}>
          <Link to='/billion' onClick={(event) => handleCheckLogin(event, '/billion')}>
            <div >
              {location.pathname === "/billion" ? (
                <SVGIcon
                  name="FooterBillionSvg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="black"
                />
              ) : (
                <SVGIcon
                  name="FooterBillionSvg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="#FFC700"
                />
                // <svg
                //   width="24"
                //   height="24"
                //   viewBox="0 0 24 24"
                //   fill="none"
                //   xmlns="http://www.w3.org/2000/svg"
                // >
                //   <path
                //     d="M21.3333 2.66667H18.6667V0H5.33333V2.66667H2.66667C1.2 2.66667 0 3.86667 0 5.33333V6.66667C0 10.0667 2.56 12.84 5.85333 13.2533C6.69333 15.2533 8.49333 16.76 10.6667 17.2V21.3333H5.33333V24H18.6667V21.3333H13.3333V17.2C15.5067 16.76 17.3067 15.2533 18.1467 13.2533C21.44 12.84 24 10.0667 24 6.66667V5.33333C24 3.86667 22.8 2.66667 21.3333 2.66667ZM2.66667 6.66667V5.33333H5.33333V10.4267C3.78667 9.86667 2.66667 8.4 2.66667 6.66667ZM12 14.6667C9.8 14.6667 8 12.8667 8 10.6667V2.66667H16V10.6667C16 12.8667 14.2 14.6667 12 14.6667ZM21.3333 6.66667C21.3333 8.4 20.2133 9.86667 18.6667 10.4267V5.33333H21.3333V6.66667Z"
                //     fill="url(#paint0_radial_385_4496)"
                //   />
                //   <defs >
                //     <radialGradient
                //       id="paint0_radial_385_4496"
                //       cx="0"
                //       cy="0"
                //       r="1"
                //       gradientUnits="userSpaceOnUse"
                //       gradientTransform="translate(15.8 6.2) rotate(122.372) scale(26.5217)"
                //     >
                //       <stop stopColor="#FFC700" />
                //       <stop offset="1" stopColor="#A7896E" />
                //     </radialGradient>
                //   </defs>
                // </svg>
              )}
            </div>
          </Link>
        </li>
        <li className={location.pathname === "/chatroom" ? style.nowTarget : ""}>
          <Link to='/chatroom' onClick={(event) => handleCheckLogin(event, '/chatroom')}>
            <>
              <SVGIcon
                name="FooterTopBorderSvg"
                width="32"
                height="3"
                viewBox="0 0 32 3"
                className={style.isNow}
              />
              <SVGIcon
                name="FooterChatroomSvg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                className={style.routerTarget}
              />
            </>
          </Link>
        </li>
        <li className={location.pathname === "/account" ? `${style.nowTarget} ${style.avatar}` : style.avatar}>
          <Link to='/account' onClick={(event) => handleCheckLogin(event, '/account')}>
            <>
              <SVGIcon
                name="FooterTopBorderSvg"
                width="32"
                height="3"
                viewBox="0 0 32 3"
                className={style.isNow}
              />
              {userInfo ?
                <img src={
                  `${avatar}`
                } alt="" /> :
                <SVGIcon
                  name="FooterAccountSvg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill={location.pathname === "/account" ? "#9C94FF" : "white"}
                  fillOpacity="0.6"
                />
              }



            </>
          </Link>
        </li>
      </ul>


    </footer>
    </IonFooter>
  );
};
export default Footer;
