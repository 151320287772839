import Footer from "./components/Footer";
import Account from "./pages/account/Account";

import { useChatroom, useSupabase } from "@DataAssetStore/chatcomponent";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import InviteSplitPopUp from "components/inviteSplitPopUp";
import { useLocalStorage } from "hooks";
import NewChatRoom from "pages/chatroom/new-chat-room";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { localGet } from "utils/localStorage";
import BuyTicket from "./components/BuyTicket";
import GiftPointsModal from "./components/GiftPointsModal";
import { GLobalLoading } from "./components/GlobalLoading";
import GlobalSyncData from "./components/GlobalSyncData";
import LoopText from "./components/LoopText";
import Topdata from "./components/TopData";
import { MyProvider } from "./components/UseContextComponent";
import GiftComponent from "./components/giftComponent";
import NotExist from "./pages/404";
import Billion from "./pages/Billion";
import JoinedGames from "./pages/JoinedGames";
import Login from "./pages/Login";
import PastGames from "./pages/PastGames";
import Upcoming from "./pages/Upcoming";
import { Friends } from "./pages/friends/Friends";
import Share from "./pages/share/Share";
import ChannelInvite from "./streamchat/ChannelInvite";
export default function MainIonApp() {
    const [isLogin] = useLocalStorage('haslogin')
    const { setUser } = useChatroom()
    const { userProfile } = useSupabase()
    useEffect(() => {
        (
            async () => {
                try {
                    const user = await userProfile()
                    setUser(user)
                }
                catch (error: any) {
                    console.log(error.message)
                }
            }
        )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <IonApp>
            <MyProvider >
                <IonReactRouter>
                    <Topdata></Topdata>
                    <LoopText></LoopText>
                    <GlobalSyncData></GlobalSyncData>
                    <ChannelInvite></ChannelInvite>
                    <GiftPointsModal></GiftPointsModal>
                    <GLobalLoading />
                    <BuyTicket></BuyTicket>
                    <GiftComponent></GiftComponent>
                    <InviteSplitPopUp />
                    <IonRouterOutlet>
                        <Switch>
                            <Route exact path="/" render={() => (
                                localGet('haslogin') ? (
                                    <Upcoming />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} >
                            </Route>
                            <Route path="/upcoming" render={() => (
                                localGet('haslogin') ? (
                                    <Upcoming />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />
                            <Route path="/friends" render={() => (
                                localGet('haslogin') ? (
                                    <Friends />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />

                            <Route path="/billion" render={() => (
                                localGet('haslogin') ? (
                                    <Billion />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />
                            <Route path="/chatRoom" render={() => (
                                localGet('haslogin') ? (
                                    <NewChatRoom />
                                    // <ChatRoom />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />
                            <Route path="/account" render={() => (
                                localGet('haslogin') ? (
                                    <Account />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />
                            <Route path="/joinedGames" render={() => (
                                localGet('haslogin') ? (
                                    <JoinedGames />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />
                            <Route path="/pastGames" render={() => (
                                localGet('haslogin') ? (
                                    <PastGames />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )} />
                            <Route exact path="/share">
                                <Share />
                            </Route>
                            <Route exact path="/login">
                                {isLogin ? <Redirect to="/upcoming" /> : <Login />}
                            </Route>
                            <Route>
                                <NotExist />
                            </Route>
                        </Switch>

                    </IonRouterOutlet>
                    <Footer></Footer>
                </IonReactRouter>
            </MyProvider>
        </IonApp>
    );
}