import { IonAlert, IonContent, IonModal } from "@ionic/react";
import { useDebounce } from "ahooks";
import { getAllFriends, newFriendList } from "api/chat";
import { useEffect, useRef, useState } from "react";
import {
  setShowLoading,
  showToastMessage,
} from "../../../../components/GlobalLoading";
import { useStore } from "../../../../store";
import style from "../../../../style/Friends.module.css";
import { getContacts } from "../../../../utils/contacts";
import { localSet } from "../../../../utils/localStorage";
import HelpModal from "../../../account/component/helpModal";
import { FriendItem } from "../groupModal";
import { isNotNullAndUndefined } from "utils/type";
interface GiftVoucherChooseProps {

}
const GiftVoucherChoose: React.FC<GiftVoucherChooseProps> = () => {
  const { isGiftAVoucherModalOpen, changeModalState } = useStore();
  const [memberList, setMemberList] = useState<FriendItem[]>([]);
  const [friendList, setFriendList] = useState<FriendItem[]>([]);
  const [searchText, setSearchText] = useState("");
  const debounceValue = useDebounce(searchText, { wait: 600 })
  // new friend
  const [type, setType] = useState("");
  const newFriendSearchRef = useRef<HTMLInputElement>(null)
  const friendSearchRef = useRef<HTMLInputElement>(null)
  const [openIOSContactsFailedAlert, setOpenIOSContactsFailedAlert] =
    useState(false);
  const [friendsName, setFriendsName] = useState("");

  const [address, setAddress] = useState("");
  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [phoneNum, setPhoneNum] = useState("");


  const handleSearch = async () => {
    const _searchText = debounceValue.trim() ?? ''
    if (type === "newFriend") {
      if (!_searchText) {
        setMemberList([])
        return
      }
      setShowLoading(true);
      try {
        const _members = await newFriendList(_searchText)
        setMemberList(_members.map(_ => ({ ..._, show: true })))

      } catch (err: any) {
        showToastMessage(err);
      }
      finally {
        setShowLoading(false);
      }

    }
    if (type === "friend") {
      const _friend = friendList.map(_ => {
        if (!_.user_name) return null
        const isInclude = _.user_name.toLowerCase().includes(_searchText.toLowerCase())
        _.show = isInclude
        return _
      }).filter(isNotNullAndUndefined)
      setFriendList(_friend)
    }
  };

  const handleOnDidPresent = async () => {
    setShowLoading(true);
    try {
      const _friends = await getAllFriends()
      setFriendList(_friends.map(_ => ({ ..._, show: true })))
      console.log(_friends)
    } catch (error: any) {
      showToastMessage(error.message)
    }
    finally {
      setShowLoading(false);
    }
  };

  const handleOnDidDismiss = () => {
    setType("");
    setSearchText("");
    setMemberList([]);
    setFriendList([]);
    changeModalState("isGiftAVoucherModalOpen", false);
  };
  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue])
  useEffect(() => {
    newFriendSearchRef?.current?.focus()
  }, [memberList])
  useEffect(() => {
    friendSearchRef?.current?.focus()
  }, [friendList])
  const handleSendBySms = async () => {
    const contactsResp: any = await getContacts();
    if (contactsResp === "failed") {
      showToastMessage("This feature is not yet supported on your device.");
      return;
    } else if (contactsResp === "ios14.5+") {
      setOpenIOSContactsFailedAlert(true);
      return;
    }
    const num = contactsResp[0]?.tel[0].replace(/-/g, "").replace(/\s/g, "");
    setPhoneNum(num);
    setFriendsName(contactsResp[0]?.name[0]);
    setType("SMS");
    // showToastMessage('The function is not available yet.')
  };

  const handleNextStep = async () => {
    setShowLoading(true);
    if (address === "") {
      setShowLoading(false);
      showToastMessage("Please enter your friend's email address.");
      return;
    }
    if (!validateEmail(address)) {
      setShowLoading(false);
      showToastMessage("Please enter the correct email address.");
      return;
    }
    setShowLoading(false);
    localSet("giftTo", {
      email: address,
      method: "email",
    });
    changeModalState("isGiftOptionsModalOpen", true);
    setType("");
    setAddress("");
  };

  return (
    <IonModal
      isOpen={isGiftAVoucherModalOpen}
      mode="ios"
      onDidPresent={handleOnDidPresent}
      onDidDismiss={handleOnDidDismiss}
    >
      <IonContent>
        <div className={style.newChatModal}>
          {type === "" ? (
            <div className={style.tit}>
              <span
                onClick={() =>
                  changeModalState("isGiftAVoucherModalOpen", false)
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div>
                <span>Gfit a Voucher</span>
              </div>
              <span style={{ width: "56px" }}></span>
            </div>
          ) : (
            <div className={style.addFriendModal}>
              <div className={style.tit} style={{ padding: 0 }}>
                {type === "newFriend" && (
                  <>
                    <span
                      onClick={() => {
                        setType("");
                        setSearchText("");
                        setMemberList([]);
                      }}
                    >
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 14L2 8L8 2"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                      </svg>
                    </span>
                    <span>Send To New Friend</span>
                    <span></span>
                  </>
                )}
                {type === "friend" && (
                  <>
                    <span
                      onClick={() => {
                        setType("");
                        setSearchText("");
                      }}
                    >
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 14L2 8L8 2"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                      </svg>
                    </span>
                    <span>Send To Friend</span>
                    <span></span>
                  </>
                )}
                {type === "email" && (
                  <>
                    <span
                      onClick={() => {
                        setType("");
                        setFriendsName("");
                        setAddress("");
                        // showToastMessage('This feature is not yet available.')
                      }}
                    >
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 14L2 8L8 2"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                      </svg>
                    </span>
                    <span>Send by Email</span>
                    <span></span>
                  </>
                )}
                {type === "SMS" && (
                  <>
                    <span
                      onClick={() => {
                        setFriendsName("");
                        setPhoneNum("");
                        setType("");
                      }}
                    >
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 14L2 8L8 2"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                      </svg>
                    </span>
                    <span>Send by SMS</span>
                    <span></span>
                  </>
                )}
              </div>
            </div>
          )}
          <div className={style.addFriendModal}>
            {type === "" && (
              <ul className={style.acts} style={{ height: "198px" }}>
                <li onClick={() => setType("friend")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_3196_1243)">
                      <path
                        d="M11.5703 11.61C14.3317 11.61 16.5703 9.37141 16.5703 6.60999C16.5703 3.84856 14.3317 1.60999 11.5703 1.60999C8.80889 1.60999 6.57031 3.84856 6.57031 6.60999C6.57031 9.37141 8.80889 11.61 11.5703 11.61Z"
                        stroke="#A2AAB5"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M2.96094 20.86C2.96094 19.71 3.30094 18.63 3.90094 17.68C5.33094 15.42 8.22094 13.87 11.5709 13.87C13.4709 13.87 15.2409 14.37 16.6609 15.22"
                        stroke="#A2AAB5"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                      <path
                        d="M13.2363 19.1672L15.8796 21.6983L20.0848 17.365"
                        stroke="#A2AAB5"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3196_1243">
                        <rect
                          width="19.58"
                          height="22.28"
                          fill="white"
                          transform="translate(2.21094 0.859985)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Send To Friend</span>
                </li>
                <li onClick={
                  () => setType("newFriend")
                  // () => showToastMessage('This feature is not yet available.')
                }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_3196_2816)">
                      <path
                        d="M11.5703 11.61C14.3317 11.61 16.5703 9.37141 16.5703 6.60999C16.5703 3.84856 14.3317 1.60999 11.5703 1.60999C8.80889 1.60999 6.57031 3.84856 6.57031 6.60999C6.57031 9.37141 8.80889 11.61 11.5703 11.61Z"
                        stroke="#A2AAB5"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M2.96094 20.86C2.96094 19.71 3.30094 18.63 3.90094 17.68C5.33094 15.42 8.22094 13.87 11.5709 13.87C13.4709 13.87 15.2409 14.37 16.6609 15.22"
                        stroke="#A2AAB5"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                      <path
                        d="M14.2734 19.4806H19.3789"
                        stroke="#A2AAB5"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3196_2816">
                        <rect
                          width="19.58"
                          height="22.28"
                          fill="white"
                          transform="translate(2.21094 0.859985)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Send To New Friend</span>
                </li>
                <li onClick={
                  // () => setType("email")
                  () => showToastMessage('This feature is not yet available.')
                }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2.75"
                      y="4.09375"
                      width="18.501"
                      height="15.8125"
                      rx="2.25"
                      stroke="#A2AAB5"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M2.76562 6.80994L11.9996 12.8904L21.2339 6.80994"
                      stroke="#A2AAB5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Send by Email</span>
                </li>
                <li onClick={handleSendBySms}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.2282 11.9999C21.2282 13.1678 21.0067 14.2828 20.6036 15.3074C20.416 15.7842 20.433 16.3577 20.4797 16.8385C20.5185 17.2371 20.5913 17.6757 20.6595 18.0866C20.6772 18.1932 20.6945 18.2978 20.7109 18.3995C20.7947 18.919 20.8551 19.3703 20.8516 19.7357C20.8479 20.129 20.7694 20.2431 20.7576 20.2555C20.684 20.3331 20.5027 20.4341 20.0885 20.4793C19.688 20.523 19.1958 20.5023 18.6322 20.4589C18.4849 20.4475 18.3312 20.4344 18.1744 20.4211C17.7608 20.3859 17.3252 20.3489 16.9271 20.3367C16.3861 20.3201 15.7861 20.3417 15.2828 20.5311C14.2641 20.9147 13.158 21.1251 12.0003 21.1251C6.89659 21.1251 2.77246 17.0323 2.77246 11.9999C2.77246 6.96754 6.89659 2.87476 12.0003 2.87476C17.1041 2.87476 21.2282 6.96754 21.2282 11.9999Z"
                      stroke="#A2AAB5"
                      strokeWidth="1.5"
                    />
                    <circle cx="8.5" cy="12" r="1.25" fill="#A2AAB5" />
                    <circle cx="12" cy="12" r="1.25" fill="#A2AAB5" />
                    <circle cx="15.5" cy="12" r="1.25" fill="#A2AAB5" />
                  </svg>
                  <span>Send by SMS</span>
                </li>
              </ul>
            )}
            {type === "newFriend" && (
              <>
                <div className={style.addSerch}>
                  <div
                    style={{
                      width: "calc(100% - 32px)",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <input
                      ref={newFriendSearchRef}
                      type="text"
                      name="search"
                      placeholder="Search new friends"
                      value={searchText}
                      onChange={async (e: any) => {
                        setSearchText(e.target.value.trim());
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                    />
                    <span onClick={handleSearch}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <ul className={style.addlists}>
                  {memberList.map((item) => (
                    <li
                      key={item.user_id}
                    // style={item.choose ? { background: "#2D323E" } : {}}
                    >
                      <div>
                        {item.avatar && item.user_name && <img src={item.avatar} alt={item.user_name} />}
                      </div>
                      <span>
                        {item.user_name}
                        <div
                          onClick={async () => {
                            localSet("giftTo", {
                              user: item.user_id,
                              method: "wallet",
                              from: "getStream",
                            });
                            changeModalState("isGiftOptionsModalOpen", true);
                            setSearchText("");
                            setMemberList([]);
                          }}
                        >
                          Choose
                        </div>
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {type === "friend" && (
              <>
                <div className={style.addSerch}>
                  <div
                    style={{
                      width: "calc(100% - 32px)",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <input
                      ref={friendSearchRef}
                      type="text"
                      name="search"
                      placeholder="Search friends"
                      value={searchText}
                      onChange={async (e: any) => {
                        setSearchText(e.target.value.trim());
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                    />
                    <span onClick={handleSearch}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <ul className={style.addlists}>
                  {friendList.map((item) => (
                    <li
                      key={item.user_id}
                      style={{ display: item.show ? "flex" : "none" }}
                    >
                      <div>
                        {item.avatar && <img src={item.avatar} alt="" />}
                      </div>
                      <span>
                        {item.user_name}
                        <div
                          onClick={async () => {
                            localSet("giftTo", {
                              user: item?.user_id,
                              method: "wallet",
                              from: "friends",
                            });
                            changeModalState("isGiftOptionsModalOpen", true);
                            setSearchText("");
                            setMemberList([]);
                          }}
                        >
                          Choose
                        </div>
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {type === "email" && (
              <>
                <div className={style.contain}>
                  <div className={style.inps}>
                    <span>Email</span>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value.trim())}
                    />
                  </div>
                </div>
                <div
                  className={style.create}
                  onClick={handleNextStep}
                >
                  Next
                </div>
              </>
            )}
            {type === "SMS" && (
              <>
                <div className={style.contain}>
                  <div className={style.inps}>
                    <span>Name</span>
                    <input
                      type="text"
                      value={friendsName}
                      onChange={(e) => setFriendsName(e.target.value.trim())}
                    />
                  </div>
                  <div className={style.inps}>
                    <span>Phone</span>
                    <input
                      type="number"
                      style={{ paddingLeft: "24px" }}
                      // disabled={true}
                      value={phoneNum}
                      onKeyDown={(e) => {
                        const { key } = e;
                        const filteredKeys = new Set(["e", "+", "-", "."]);
                        if (filteredKeys.has(key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setPhoneNum(
                          e.target.value
                            .replace(/^0+/, "")
                            .replace(/[^\d]/g, "")
                        )
                      }
                    />
                    <div>+1</div>
                  </div>
                </div>
                <div
                  className={style.create}
                  onClick={() => {
                    changeModalState("isGiftOptionsModalOpen", true);
                  }}
                >
                  Next
                </div>
              </>
            )}
          </div>
        </div>
        <IonAlert
          isOpen={openIOSContactsFailedAlert}
          onDidDismiss={() => {
            setOpenIOSContactsFailedAlert(false);
          }}
          mode="ios"
          message="Need to open Contact Picker API in Safari settings to use contact function, do you want to watch the help video?"
          buttons={[
            {
              text: "Yes",
              role: "yes",
              handler: async () => {
                changeModalState("isHelpModalOpen", true);
              },
            },
            {
              text: "No",
              role: "no",
              handler: async () => { },
            },
          ]}
        />
        {/* help modal  */}
        <HelpModal />
      </IonContent>
    </IonModal>
  );
};
export default GiftVoucherChoose;
