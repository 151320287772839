import { IonContent, IonModal } from "@ionic/react";
import { useState } from "react";

import { useChatroom } from "@DataAssetStore/chatcomponent";
import { getRoomMembers } from "api/chat";
import { setShowLoading, showToastMessage } from "components/GlobalLoading";
import { localSet } from "utils/localStorage";
import { useStore } from "../../../store";
import style from './index.module.css';
interface GroupMember {
    user_avatar: string | null;
    user_id: string | null;
    user_name: string | null;
}
interface GroupVoucherChooseProps {

}
const GroupVoucherChoose: React.FC<GroupVoucherChooseProps> = () => {
    const { currentChatroom } = useChatroom()
    const { changeModalState, isGroupVoucherChooseModalOpen } = useStore()
    const [members, setMembers] = useState<GroupMember[]>([])
    // const [userInfo] = useLocalStorage<User | null>('user_info')
    const getMembers = async () => {
        setShowLoading(true)
        try {
            if (!currentChatroom || !currentChatroom.room_id) return
            const data = await getRoomMembers(currentChatroom.room_id)
            setMembers(data)
        }
        catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            setShowLoading(false)
        }
    }
    const onHandleCloseModal = () => {
        changeModalState('isGiftAVoucherModalOpen', false)
    }
    const onChooseMember = (user_id: string) => {
        return () => {
            changeModalState('isGiftOptionsModalOpen', true)
            localSet('giftTo', {
                user: user_id,
                method: 'wallet',
                from: 'friends'
            })
        }
    }
    return (
        <IonModal
            isOpen={isGroupVoucherChooseModalOpen}
            className={style.groupVoucherChoose}
            onDidDismiss={onHandleCloseModal}
            onWillPresent={getMembers}
        >
            <IonContent>
                <div className={style.giftOption}
                // style={context.giftVocher.inFriends ? {} : { minHeight: 'calc(100vh - 83px)' }}
                >
                    <div className={style.tit}>
                        <span onClick={() => changeModalState('isGroupVoucherChooseModalOpen', false)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                            </svg>
                        </span>
                        <div>
                            <span>Gift a Voucher</span>
                        </div>
                        <span style={{ width: '56px' }} ></span>
                    </div>
                    <div className={style.main}>
                        <ul className={style.addlists}>
                            {
                                members?.map((member) => (
                                    <li key={member?.user_id}>
                                        <div>
                                            {member.user_avatar && <img src={member.user_avatar} alt="" />}
                                        </div>
                                        <span>
                                            {member.user_name}
                                            {member.user_id && <div onClick={onChooseMember(member.user_id)}>Choose</div>}
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

            </IonContent>
        </IonModal>

    );
}

export default GroupVoucherChoose;