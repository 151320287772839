import { IonContent, IonModal } from '@ionic/react';
import { User } from '@supabase/supabase-js';
import { retrieveUser, updateAccount } from 'api/account';
import { setShowLoading, showToastMessage } from 'components/GlobalLoading';
import { derivePinCode } from 'db/schema/account';
import { saveWalletInfo } from 'db/schema/wallet';
import { ethers } from 'ethers';
import { useUser } from 'hooks';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { FC, useRef, useState } from 'react';
import { localGet, localSet } from 'utils/localStorage';
import * as Database from '../../db';
import { useStore } from '../../store';
import style from "../../style/Upcoming.module.css";
interface PinCodeModalProps {
    getUser: (user?: User) => void
}
export const PinCodeModal: FC<PinCodeModalProps> = ({ getUser }) => {
    const isOnline = useNetworkStatus()
    const refs = useRef<any>([]);
    const [code, setCode] = useState(["", "", "", ""])
    const [inpAct, setInpAct] = useState([false, false, false, false])
    const { updateProfile } = useUser()
    const [shake, setShake] = useState(false);

    const { inFriends, isPincodeModalOpen, closeAllModalStates, setUserInfo } = useStore()

    const createWalletAddress = async (_userInfo: User | null) => {
        if (!_userInfo) return
        const newWallet = ethers.Wallet.createRandom();
        const privateKey = newWallet.privateKey
        const myMnemonic = newWallet.mnemonic
        const myWallet = {
            address: newWallet.address,
            pKey: privateKey,
            mnemonic: myMnemonic
        }
        saveWalletInfo(newWallet.address, privateKey, myMnemonic);
        const db = await Database.get()
        const addData = {
            walletInfo: myWallet,
            walletAddress: newWallet.address,
            userName: _userInfo.user_metadata.user_name,
            email: _userInfo.email,
            pin: _userInfo.user_metadata.pin_code,
            dateCreated: new Date().toISOString().slice(0, -5) + '+00:00',
        }
        await db.account.insert(addData)
        await updateProfile({ wallet: newWallet.address })
        const data = await retrieveUser()
        if (!data) return
        localSet('authAddress', data.user_metadata.user_wallet)
        localSet('user_info', data)
        setUserInfo(data)
        closeAllModalStates(false)
        getUser(data)
    }
    const updateLocalUserInfo = async () => {
        try {
            const user_data = await retrieveUser()
            await createWalletAddress(user_data)
        }
        catch (error: any) {
            showToastMessage(error.message ?? 'Unknown Error')
        }
    }
    const confirmPinCode = async (newCode: string[]) => {
        setShowLoading(true)
        if (!isOnline) {
            setShowLoading(false)
            showToastMessage('Please check your network and try later.')
            return
        }
        const userInfo: User = localGet('user_info')
        if (newCode.length === 4) {
            const encryptedPinCode = await derivePinCode(newCode)
            try {
                await updateAccount({ id: userInfo.id, pinCode: encryptedPinCode })
                setShowLoading(false)
                localSet('has_pin_code', true)
                closeAllModalStates(false)
                await updateLocalUserInfo()

            }
            catch (error: any) {
                showToastMessage(error.message)
            }

        }
        /*         validatePinCode(newCode).then(async (res: any) => {
                    if (navigator.onLine === false) {
                        setShowLoading(false)
                        showToastMessage('Please check your network and try later.')
                        return
                    }
                    if (res === true) {

                        // await buyFunc()
                        setShowLoading(false)
                    } else {
                        showToastMessage('Incorrect PIN.')
                        setShowLoading(false)
                        Haptics.vibrate()
                        setShake(true)
                        setCode(['', '', '', ''])
                        setInpAct([false, false, false, false])
                        setTimeout(() => {
                            refs.current[0].focus()
                        }, 0);
                    }

                }) */

    }
    // auto focus
    // useEffect(() => {
    //     setTimeout(() => {
    //         refs.current[0]?.focus()
    //     }, 500);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const handleFocus = () => {
        setTimeout(() => {
            refs.current[0]?.focus()
        }, 300);
    }

    const handleInputChange = async (event: any, index: number) => {
        const { value } = event.target;

        if (value.length > 1) return
        const newCode = [...code];
        newCode[index] = value;

        setCode(newCode);
        newCode.map((item: any, i: any) => {
            if (item !== '') {
                inpAct[i] = true
            } else {
                inpAct[i] = false
            }
            return setInpAct(inpAct)
        })
        if (value.length === 1 && index < code.length - 1) {
            refs.current[index + 1].focus();
        } else if (value.length === 0 && index > 0) {
            refs.current[index - 1].focus();
        }

        if (index === newCode.length - 1) {
            confirmPinCode(newCode)
        }

    }

    const deleteOne = (event: any) => {
        const key = event.keyCode || event.charCode;
        const newCode = [...code];
        const newInpAct = [...inpAct]
        if (key === 8 || key === 46) {
            if (code[0].length > 0 && code[1].length === 0 && code[2].length === 0 && code[3].length === 0) {
                newCode[0] = ''
                newInpAct[0] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[0].focus();
            }
            if (code[0].length > 0 && code[1].length > 0 && code[2].length === 0 && code[3].length === 0) {
                newCode[1] = ''
                newInpAct[1] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[1].focus();
            }
            if (code[0].length > 0 && code[1].length > 0 && code[2].length > 0 && code[3].length === 0) {
                newCode[2] = ''
                newInpAct[2] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[2].focus();
            }
            if (code[0].length > 0 && code[1].length > 0 && code[2].length > 0 && code[3].length > 0) {
                newCode[3] = ''
                newInpAct[3] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[3].focus();
            }
        }
    }

    // auto focus
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (refs.current[0]) {
    //             refs.current[0].focus()
    //         }
    //     }, 300);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [refs])
    return (
        <IonModal
            isOpen={isPincodeModalOpen}
            className={inFriends ? style.pincodeInFriends
                : style.pincodeNotFriends}
            onDidPresent={handleFocus}
            onDidDismiss={() => {
                setCode(['', '', '', ''])
                setInpAct([false, false, false, false])
                closeAllModalStates(false)
            }}
        >
            <IonContent className="ion-padding">
                <div className={style.pinsmain}>
                    {/*                     <div className={style.pinsTop} onClick={() => {
                        closeModal()
                    }}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                        </svg>
                    </div> */}
                    <div className={style.pinDes}>
                        <div>Enter Your PIN Code</div>
                        <p className={style.pinsp}>Please enter the PIN code of the wallet</p>
                    </div>
                    <ul className={style.pinsul}>
                        {code.map((value: any, index: any) => (
                            <li
                                key={index}
                                style={shake ? { animation: " shake 0.8s", borderColor: "red" } : {}}
                                onAnimationEnd={() => {
                                    setShake(false);
                                    refs.current[0].focus()
                                }}>
                                <input
                                    type="number"
                                    value={value}
                                    maxLength={1}
                                    autoFocus={index === 0 ? true : false}
                                    data-index={index}
                                    onKeyDown={(event) => deleteOne(event)}
                                    onChange={(event) => handleInputChange(event, index)}
                                    ref={(input) => (refs.current[index] = input)}
                                />
                                {/*                                 <div
                                    className={inpAct[index] === true ? style.pinsudiv : style.nonepinsudiv}>
                                    *
                                </div> */}
                            </li>
                        ))}
                    </ul>
                    <div className={style.pinsbtn} onClick={() => confirmPinCode(code)}>Confirm</div>
                </div>
            </IonContent>
        </IonModal >
    )
}

export default PinCodeModal