import { IonAlert, IonContent, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";

import style from "../../style/Account.module.css";

import { useEffect, useState, } from "react";
import { localGet, localSet } from "../../utils/localStorage";
// import { myBalance } from '../db/schema/balanceHistory'
import { selectBalanceHistory } from "../../db/schema/balanceHistory";
import { LogoutDrawsDBAction } from "../../db/schema/draws";
import { formatDate } from "../../utils/formatDate";
// import back from "../../image/account/back.png"

// import JoLPlayer from "jol-player";

import { User } from "@supabase/supabase-js";
import SVGIcon from "components/SvgComponent";
import { useNetworkStatus, useUser } from "hooks";
import useLocalStorage from "hooks/useLocalStorage";
import { handleAvatar } from "utils/avatar";
import { setShowLoading, showToastMessage } from "../../components/GlobalLoading";
import { selectPoints } from "../../db/schema/points";
import { selectTicketsByOwner } from "../../db/schema/tickets";
import { selectUser } from "../../db/schema/user";
import { getWalletInfo } from "../../db/schema/wallet";
import { useStore } from '../../store';
import { addCommas } from '../../utils/addCommas';
import clearCache from "../../utils/clearCache";
import MyVoucher from "./component/MyVoucher";
import AddBankAccountModal from "./component/addBankAccountModal";
import AddPrivateKeyModal from "./component/addPrivateKeyModal";
import DepostiModal from "./component/depositModal";
import DrawRecordModal from "./component/drawRecordModal";
import DriverLicenseModal from "./component/driverLicenseModal";
import HelpModal from "./component/helpModal";
import ManageBankAccountModal from "./component/manageBankAccountModal";
import MyticketsModal from "./component/myticketsModal";
import SettingsModal from "./component/settingsModal";
import TakeASelfieModal from "./component/takeASelfieModal";
import TransactionHistoryModal from "./component/transactionHistoryModal";
import UploadPhotoIDModal from "./component/uploadPhotoIDModal";
import UploadResultModal from "./component/uploadResultModal";
import UserProfileModal from "./component/userProfileModal";
import VerifyIdentityModal from "./component/verifyIdentityModal";
import WithdrawalSuccessfulModal from "./component/withdrawalSuccessfulModal";
import WithdrawnModal from "./component/withdrawnModal";

const Account: React.FC = () => {
  const isOnline = useNetworkStatus()
  const { globalUserInfo, isNotSupportsModalOpen, isLogoutAlertOpen, isClearDatabaseModalOpen, changeModalState, closeAllModalStates } = useStore();
  // const { user, isAuthenticated } = useAuth0()
  // const { logout } = useAuth0();
  const { logout } = useUser()
  const [user, setUser] = useState<{ email: string, nickname: string, avatar?: string }>()
  const [localStorageUserInfo] = useLocalStorage<User>('user_info');
  const userInfo = localStorageUserInfo ?? globalUserInfo;
  /*   const [accountInfo, setAccountInfo] = useState<any>([])
    const history = useHistory() */
  // transaction history modal
  const { getProfile } = useUser()
  const [syncBalace, setSyncBalace] = useState<any>(0)
  const [syncPoints, setSyncPoints] = useState<any>(0)
  const [balanceData, setBalancaData] = useState([])

  const getdatas = async (type = 'money', sort = 'desc', defaultPageSize = 10, defaultPage = 1) => {
    if (!userInfo) return
    if (type === 'money') {
      await selectBalanceHistory(sort, defaultPage, defaultPageSize).then((res) => {
        const balanceDatas = res.map((i: any) => {
          const item = i.toJSON();
          const newItem = { ...item, date: formatDate(new Date(item.date)), amount: item?.amount?.toLocaleString() }
          return newItem;
        })
        console.log('money', balanceDatas)
        setBalancaData(balanceDatas)
      })
    } else {
      await selectPoints(sort, defaultPage, defaultPageSize).then((res) => {
        const pointsDatas = res.map((i: any) => {
          const item = i;
          const newItem = { ...item, date: formatDate(new Date(item.date)), amount: item?.amount?.toLocaleString() }
          return newItem;
        })
        setBalancaData(pointsDatas)
      })
    }

    // const db = await Database.get();
    /*     const users = db.account.findOne({
          selector: {
            walletAddress: localGet('authAddress')
          }
        }) */
    // const userResult = await users.exec()
    // setAccountInfo(userResult)
  }
  /*   useEffect(() => {
      getdatas()
    }, [user, localStorage.getItem('userBalance')]) */

  // get wallet info
  const [walletInfo, setWalletInfo] = useState<any>()
  const getWallet = async () => {
    const info = await getWalletInfo()
    setWalletInfo(info)
  }
  useEffect(() => {
    getWallet()
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //  upload a photo id modal
  const [imgUrl, setImgUrl] = useState<any>()

  // driver license modal
  const [modalContent, setModalContent] = useState('')

  // my tickets modal
  const [ticketlist, setTicketList]: any = useState([])

  // get my tickets
  async function getMyTickets() {
    const tickets = await selectTicketsByOwner(5, 1, {
      owner: localGet('authAddress'),
      state: 'activate',
    })
    setTicketList(tickets)
  }

  // const { loginWithRedirect } = useAuth0()
  // const refesstiondata = localGet('userBalance')
  // useEffect(() => {
  //   const syncData = async () => {
  //     if (localGet('balance') !== null && localGet('balance') !== undefined) {
  //       await setTimeout(async () => {
  //         selectBalanceHistory('desc', 1, 10).then(async (res) => {
  //           const balanceDatas = res.map((i: any) => {
  //             const item = i.toJSON();
  //             const newItem = { ...item, date: formatDate(new Date(item.date)), amount: item?.amount?.toLocaleString() }
  //             return newItem;
  //           })
  //           setBalancaData(balanceDatas)
  //         })
  //       }, 1500);
  //     }
  //   }
  //   syncData()
  // }, [refesstiondata])
  // const [userInfo, setUserInfo] = useState<any>(null)
  useEffect(() => {
    if (!userInfo) return
    selectUser().then((res) => {
      setSyncBalace(res?.money || 0)
      setSyncPoints(res?.points || 0)
      if (!res) return
      setUser({ nickname: res.nickname, email: userInfo.email!, avatar: handleAvatar(res._data?.avatarURL) });
    })
    PubSub.subscribe('syncData', function (msg, searchName) {
      if (searchName.user) {
        setSyncBalace(searchName?.user.money)
        setSyncPoints(searchName?.user.points)
        setUser(prev => ({ nickname: searchName?.user.nickname || prev?.nickname, email: userInfo.email!, avatar: handleAvatar(searchName?.user?.avatarURL || prev?.avatar) }));
      }
    })
  }, [userInfo])

  const handleLogOut = async () => {
    if (!isOnline) {
      showToastMessage('Please check your network and try later.')
      return
    }
    changeModalState('isLogoutAlertOpen', true)
  }
  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className={style.account}>

          <div className={style.topDes}>
            <span onClick={() => changeModalState('isEditModalOpen', true)}>
              <SVGIcon
                name="AccountEdit"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              />
            </span>
            <div className={style.avatarAnd}>
              <img src={
                // `${userInfo?.auth0AvatarURL}?${new Date().getTime()}`
                `${user?.avatar}`
              } alt="" />
              <div>
                <span>{user?.nickname}</span>
                <p>{user?.email}</p>
                <p> {localGet('authAddress')}</p>
              </div>
            </div>
            <div className={style.moneyAndAct}>

              <div className={style.money}>
                <span>My Balance</span>
                <div>
                  <p>
                    <SVGIcon name="Diamond" width="24" height="23" viewBox="-4 0 18 15" />
                  </p>
                  <p>{syncPoints?.toLocaleString()}</p>
                </div>
                <div>
                  <p>
                    <SVGIcon name="AccountDollar" width="9" height="18" viewBox="0 0 9 18" />
                  </p>

                  <p>{addCommas(syncBalace)}</p>
                </div>

              </div>
              <div className={style.top}>
                <div className={style.act}>
                  <div className={style.drawn} onClick={() => changeModalState('isWithDrawnModalOpen', true)}>Withdrawal</div>
                  <div className={style.depo} onClick={() => changeModalState('isDepositModalOpen', true)}>Deposit</div>
                </div>
              </div>
              {/* <div className={style.bot}>
                <p>Wallet Address:</p>
                {localGet('authAddress')}
              </div> */}
            </div>
            {/* <div onClick={() => {
              console.log(navigator.share)
              navigator.share({
                title: 'Invite you to join OIAM',
                text: 'I hope this email finds you. I would like to connect and invite you to join OIAM, a social networking app that also offers fun and exciting lottery games. ',
                url: 'https://oneinamillion.site/upcoming'
              })
                .then(() => {
                })
                .catch((error) => {
                  console.error('error:', error);
                });
            }}>share</div> */}

            {/* <div onClick={async () => {
              await Share.share({
                title: 'Invite you to join OIAM',
                text: 'I hope this email finds you. I would like to connect and invite you to join OIAM, a social networking app that also offers fun and exciting lottery games. ',
                url: 'https://oneinamillion.site/upcoming',
                dialogTitle: 'Invite you to join OIAM',
              });
            }}>
              ion share
            </div> */}
          </div>
          <ul className={style.actions}>
            <li onClick={() => {
              getMyTickets()
              changeModalState('isMyTicketsModalOpen', true)
            }}>
              <div>
                <SVGIcon name="AccountTicketsIcon" width="24" height="24" fill="#A6A9B8" viewBox="0 0 24 24" />
                <span>My Tickets</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => {
              changeModalState('isVoucherModalOpen', true)
            }}>
              <div>
                <SVGIcon name="AccountVouchersIcon" width="21" height="22" viewBox="0 0 21 22" />
                <span>My Vouchers</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => {
              changeModalState('isGameRecordModalOpen', true)
            }}>
              <div>
                <SVGIcon name="AccountRecordIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Draw Record</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => { changeModalState('isBalanceModalOpen', true) }}>
              <div >
                <SVGIcon name="AccountHistoryIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Transaction History</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => { changeModalState('isManageBankModalOpen', true) }}>
              <div>
                <SVGIcon name="AccountBankIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Manage Bank Account</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li>
              <div>
                <SVGIcon name="AccountReferralsIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Referrals</span>
                <p>
                  Refer a friend to get 100
                  <SVGIcon name="ReferralsDiamond" width="11" height="9" viewBox="0 0 11 9" />
                </p>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => { changeModalState('isSettingModalOpen', true) }}>
              <div>
                <SVGIcon name="AccountSettingsIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Settings</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => changeModalState('isHelpModalOpen', true)}>
              <div>
                <SVGIcon name="AccountHelpIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Help</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={() => {
              if (navigator.onLine === false) {
                showToastMessage('Please sign into your account first.')
                return
              }
              setShowLoading(true)
              let url: any = process.env.REACT_APP_FEEDBACK
              // window.location.href = 'https://survey.zohopublic.com/zs/QyCNqC'
              window.location.href = url
            }}>
              <div>
                <SVGIcon name="AccountFeedbackIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Feedback</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li onClick={handleLogOut}>
              <div>
                <SVGIcon name="AccountLogoutIcon" width="24" height="24" viewBox="0 0 24 24" />
                <span>Logout</span>
              </div>
              <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24" />
            </li>
            <li style={{ display: "flex", justifyContent: 'center', fontSize: '12px', color: '#ccc' }}>
              - version: {process.env.REACT_APP_VERSION} -
            </li>
          </ul>

          {/* deposit modal  */}
          <DepostiModal
            getdatas={getdatas}
            addCommas={addCommas}
          />

          {/* user profile modal  */}
          <UserProfileModal
            user={user}
            getdatas={getdatas}
            walletInfo={walletInfo}
            userInfo={userInfo}
          />

          {/* add private key modal  */}
          <AddPrivateKeyModal
            getdatas={getdatas}
            getWallet={getWallet}
          />

          {/* Transaction History modal */}
          <TransactionHistoryModal
            syncBalace={syncBalace}
            syncPoints={syncPoints}
            balanceData={balanceData}
            setBalancaData={setBalancaData}
            getdatas={getdatas}
          />

          {/* help modal  */}
          <HelpModal />

          {/* settings modal */}
          <SettingsModal />

          {/* verify identity modal  */}
          <VerifyIdentityModal />

          {/* upload a photo id modal  */}
          <UploadPhotoIDModal
            setModalContent={setModalContent}
          />

          {/* driver license modal  */}
          <DriverLicenseModal
            modalContent={modalContent}
            setImgUrl={setImgUrl}
          />

          {/* take A Selfie Modal */}
          <TakeASelfieModal />

          {/* upload result modal */}
          <UploadResultModal
            imgUrl={imgUrl}
            setImgUrl={setImgUrl}
          />

          {/* Withdrawn modal */}
          <WithdrawnModal
            syncBalace={syncBalace}
            syncPoints={syncPoints}
          />

          {/* Withdrawal Successful modal */}
          <WithdrawalSuccessfulModal />

          {/* Add a Bank Account modal */}
          <AddBankAccountModal />


          {/* Manage Bank Account modal */}
          <ManageBankAccountModal />

          {/* My tickets modal mytickets*/}
          <MyticketsModal
            gets={getMyTickets}
            ticketlist={ticketlist}
          />

          {/* My Voucher */}
          <MyVoucher />

          {/* Draw Record modal gamerecord*/}
          <DrawRecordModal />
          {/* email share  */}
          {/* <EmailInput
            emailInputModal={emailInputModal}
            setEmailInputModal={setEmailInputModal}
            showToastMessage={showToastMessage}
            setShowLoading={setShowLoading}
          /> */}
          {/* ask to clear database  */}
          <IonAlert
            isOpen={isClearDatabaseModalOpen}
            onDidDismiss={() => {
              changeModalState('isClearDatabaseModalOpen', false)
            }}
            mode="ios"
            message="Do you need to clear the database?"
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: async () => {
                  changeModalState('isClearDatabaseModalOpen', false)
                },
              },
              {
                text: 'OK',
                role: 'confirm',
                handler: async () => {
                  try {
                    setShowLoading(true)
                    if (!window.indexedDB) {
                      changeModalState('isNotSupportsModalOpen', true)
                    } else {
                      // await clearIndexedDB()
                      // window.localStorage.clear()
                      // localStorage.setItem('haslogin', 'true')
                      // setTimeout(() => {
                      //   setShowLoading(false)
                      // }, 2000);
                      // setOpenClearDatabase(false)
                      // loginWithRedirect()
                      await clearCache();
                      localSet('haslogin', true)
                      const user_data = await getProfile()
                      if (user_data) {
                        localSet('user_info', user_data)
                      }
                      if (user_data?.user_metadata.pin_code) {
                        localSet('has_pin_code', true)
                        localSet('authAddress', user_data.user_metadata.user_wallet)
                      } else {
                        closeAllModalStates(false)
                        changeModalState('isPincodeModalOpen', true)
                      }
                      window.location.href = '/'

                    }
                  }
                  catch (error) {
                    console.log({ CacheError: error })
                  }
                  finally {
                    setShowLoading(false)
                  }

                }
              }
            ]}
          />
          {/* ask to logout  */}
          <IonAlert
            isOpen={isLogoutAlertOpen}
            onDidDismiss={() => {
              changeModalState('isLogoutAlertOpen', false)
            }}
            mode="ios"
            message="Need to go back to the secure login?"
            buttons={[
              {
                text: 'Yes',
                role: 'yes',
                handler: async () => {
                  setShowLoading(true)
                  if (!window.indexedDB) {
                    setShowLoading(false)
                    changeModalState('isNotSupportsModalOpen', true)
                  } else {
                    LogoutDrawsDBAction((onCompletion: boolean) => {
                      if (onCompletion) {
                        setShowLoading(false)
                        logout({ isNeedCaptcha: true })
                      }
                    })
                  }
                },
              },
              {
                text: 'No',
                role: 'no',
                handler: async () => {
                  setShowLoading(true)
                  if (!window.indexedDB) {
                    setShowLoading(false)
                    changeModalState('isNotSupportsModalOpen', true)
                  } else {
                    LogoutDrawsDBAction((onCompletion: boolean) => {
                      if (onCompletion) {
                        setShowLoading(false)
                        logout()
                      }
                    })
                  }
                }
              }
            ]}
          />
          {/* no support alert  */}
          <IonAlert
            isOpen={isNotSupportsModalOpen}
            onDidDismiss={() => {
              changeModalState('isNotSupportsModalOpen', false)
            }}
            mode="ios"
            message="Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available."
            buttons={[
              {
                text: 'OK',
                role: 'confirm',
                handler: async () => {
                  changeModalState('isNotSupportsModalOpen', false)
                }
              }
            ]}
          />
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Account;
