import { externalJWT } from 'api/auth';
import { localGet } from '../utils/localStorage';
import { objectToFormData } from '../utils/toFormData';
export function endsWithSlash(str) {
  const regex = /\/$/;
  let isHasSlash = regex.test(str);
  if (isHasSlash) {
    return str;
  } else {
    let hasSlash = str + '/';
    return hasSlash;
  }
}
// const MATHINE_AUDIENCE = endsWithSlash(process.env.REACT_APP_MATHINE_AUDIENCE);
// const DOMAIN = endsWithSlash(process.env.REACT_APP_DOMAIN);
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
// const MATHINE_CLIENT_ID = process.env.REACT_APP_MATHINE_CLIENT_ID;
// const MATHINE_SECRET = process.env.REACT_APP_MATHINE_SECRET;
// const ROLE = process.env.REACT_APP_NORMAL_USER;

export const api = async (url = '', data = {}, type = 'GET') => {
  const baseUrl = BACKEND_BASE_URL;
  type = type.toUpperCase();
  url = baseUrl + url;

  if (type === 'GET') {
    let dataStr = '';
    Object.keys(data).forEach((key) => {
      dataStr += key + '=' + data[key] + '&';
    });
    if (dataStr !== '') {
      dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'));
      url = url + '?' + dataStr;
    }
  }
  let requestConfig = {
    credentials: 'same-origin',
    method: type,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors', //  same-origin，no-cors（default） cors;
    cache: 'force-cache' // default 、 no-store 、 reload 、 no-cache 、 force-cache and only-if-cached 。
  };

  if (type === 'POST') {
    Object.defineProperty(requestConfig, 'body', {
      value: JSON.stringify(data)
    });
  }
  try {
    const response = await fetch(url, requestConfig);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    // throw new Error(error)
  }
};
export const tokenApi = async (url = '', data = {}) => {
  const exp = await externalJWT();
  const idToken = localGet('id_token') ?? exp.data;
  const baseUrl = BACKEND_BASE_URL;
  url = baseUrl + url;
  let requestConfig = {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${idToken}`
    },
    mode: 'cors', //  same-origin，no-cors（default） cors;
    cache: 'force-cache' // default 、 no-store 、 reload 、 no-cache 、 force-cache and only-if-cached 。
  };
  if (Object.keys(data).length > 0) {
    requestConfig.body = JSON.stringify(data);
  }
  const response = await fetch(url, requestConfig);
  const responseJson = await response.json();
  return responseJson;
};

export const formDatatokenApi = async (url = '', data = {}) => {
  const exp = await externalJWT();
  const idToken = localGet('id_token') ?? exp.data;
  const baseUrl = BACKEND_BASE_URL;
  url = baseUrl + url;
  const formData = objectToFormData(data);
  let requestConfig = {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      authorization: `Bearer ${idToken}`
    },
    body: formData,
    mode: 'cors', //  same-origin，no-cors（default） cors;
    cache: 'force-cache' // default 、 no-store 、 reload 、 no-cache 、 force-cache and only-if-cached 。
  };
  try {
    const response = await fetch(url, requestConfig);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    // throw new Error(error)
  }
};

/* export const getToken = async () => {
  let token;
  await fetch(`https://${DOMAIN}oauth/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      grant_type: 'client_credentials',
      client_id: MATHINE_CLIENT_ID,
      client_secret: MATHINE_SECRET,
      audience: MATHINE_AUDIENCE
    })
  })
    .then((res) => res.json())
    .then((res) => (token = res.access_token))
    .catch((err) => console.log(err));

  return token;
}; */

/* export const getUser = async (user_id) => {
    let res
    let token = await getToken()
    let url
    if (user_id.includes('siwe')) {
        url = user_id.replace(/%/g, '%25')
    } else {
        url = user_id
    }
    await fetch(
        `${MATHINE_AUDIENCE}users/${url}?include_fields=true`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    })
        .then(res => res.json())
        .then(data => res = data)
        .catch(err => console.log(err))
    return res
} */

/* export const updateUser = async (user_id, data) => {
  let res;
  let token = await getToken();
  let url;
  if (user_id.includes('siwe')) {
    url = user_id.replace(/%/g, '%25');
  } else {
    url = user_id;
  }
  await fetch(`${MATHINE_AUDIENCE}users/${url}`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      user_metadata: data
    })
  })
    .then((res) => res.json())
    .then((result) => (res = result))
    .catch((err) => console.log(err));
  return res;
}; */
/* export const updateUserImg = async (user_id, data) => {
  let res;
  let token = await getToken();
  let url;
  if (user_id.includes('siwe')) {
    url = user_id.replace(/%/g, '%25');
  } else {
    url = user_id;
  }
  await fetch(`${MATHINE_AUDIENCE}users/${url}`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      picture: data
    })
  })
    .then((res) => res.json())
    .then((result) => (res = result))
    .catch((err) => console.log(err));
  return res;
}; */

/* export const getUserRole = async (user_id) => {
  let res;
  let token = await getToken();
  let url;
  if (user_id.includes('siwe')) {
    url = user_id.replace(/%/g, '%25');
  } else {
    url = user_id;
  }
  await fetch(`${MATHINE_AUDIENCE}users/${url}/roles`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    }
  })
    .then((res) => res.json())
    .then((data) => (res = data))
    .catch((err) => console.log(err));
  return res;
}; */

/* export const assignUserRole = async (user_id, data = ROLE) => {
  let res;
  let token = await getToken();
  let url;
  if (user_id.includes('siwe')) {
    url = user_id.replace(/%/g, '%25');
  } else {
    url = user_id;
  }
  await fetch(`${MATHINE_AUDIENCE}users/${url}/roles`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      roles: [data]
    })
  })
    .then((res) => res)
    .then((data) => (res = data))
    .catch((err) => console.log(err));
  return res;
}; */
