import { IonAvatar, IonContent, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonModal } from '@ionic/react';
import { getInviteInfo, handleInviterAccepted, handleInviterRejected } from 'api/chat';
import { getDrawsByDrawId } from "db/schema/draws";
import { getAllImages } from "db/schema/images";
import { selectInvitesCountByinviteesId } from "db/schema/invites";
import { selectTicketByTicketNumber } from "db/schema/tickets";
import { FC, useState } from "react";
import { Draw } from "typings/draw";
import { Inviter, InviterState, Ticket } from "typings/ticket";
import { formatPrize, ticketPrize } from "utils/formatPrize";
import { localGet } from "utils/localStorage";
import { tokenApi } from "../api";
import DrawAvatar from "../image/topdata/4.png";
import avatarGroup from '../image/friends/avatarGroup.jpg';
import img1 from "../image/upcoming/1.png";
import { useStore } from "../store";
import style from "../style/Notification.module.css";
import { AdditionalInfo, NotificationInfo, NotificationMessageType } from "../typings/notification";
import { formatDate } from "../utils/formatDate";
import { setShowLoading, showToastMessage } from "./GlobalLoading";
import PinCode from './PinCode';
import Button from "./common/Button";
export interface PageConfig {
    pageNumber: number,
    pageSize: number
}
interface NotificationProps {
    list: NotificationInfo[],
    getList: (pageConfig: PageConfig) => void,
    setNotify: (_id: string) => void
    isNoMore: boolean
}
interface NotificationButtonProps {
    item: NotificationInfo,
    setNotify: (_id: string) => void
    setDraw: React.Dispatch<React.SetStateAction<Draw | undefined>>
    setIsBuySplitTicketOpen: React.Dispatch<React.SetStateAction<boolean>>
    setInfo: React.Dispatch<React.SetStateAction<AdditionalInfo | undefined>>
    setTicket: React.Dispatch<React.SetStateAction<Ticket | undefined>>
}

const NotificationButton = ({ item, setNotify, setDraw, setInfo, setTicket, setIsBuySplitTicketOpen }: NotificationButtonProps) => {
    const { setGlobalState } = useStore();
    const onClickHandler = async <T extends () => any>(fn: T) => {
        setShowLoading(true)
        try {
            const { code, detail } = await fn()

            switch (Number(code)) {
                case 200:
                    setNotify(item._id)
                    break;
                default:
                    showToastMessage(detail ?? 'Unknown Error.')
                    break;
            }
        } catch (notificationError) {
            console.log({ notificationError })
        }
        finally {
            setShowLoading(false)
        }
    }
    const onAcceptInvite = async (event: ButtonEvent) => {
        event.stopPropagation()
        if (!item.objectId.channelId) return
        try {
            setShowLoading(true)
            const { inviteId } = await getInviteInfo(item.objectId.channelId)
            const isGroup = item.type === NotificationMessageType.invite_group_chat
            await handleInviterAccepted(inviteId, isGroup)
            const num = await selectInvitesCountByinviteesId()
            showToastMessage('The invitation was accepted successfully.')
            PubSub.publish('inviteNum', num)
            PubSub.publish('initInviteList')
            setNotify(item._id)
        }
        catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            setShowLoading(false)
        }
    }
    const onRejectInvite = async (event: ButtonEvent) => {
        event.stopPropagation()
        if (!item.objectId.channelId) return
        try {
            setShowLoading(true)
            const { inviteId, isGroup } = await getInviteInfo(item.objectId.channelId)
            await handleInviterRejected(inviteId, isGroup)
            const num = await selectInvitesCountByinviteesId()
            showToastMessage('The invitation was refused successfully.')
            PubSub.publish('inviteNum', num)
            PubSub.publish('initInviteList')
            setNotify(item._id)
        }   
        catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            setShowLoading(false)
        }
    }
    const onAcceptVoucher = async (event: ButtonEvent) => {
        event.stopPropagation()
        onClickHandler(() => {
            return tokenApi('/acceptVoucher', {
                voucherId: item.objectId.voucherId
            })
        })
    }
    const onRejectVoucher = async (event: ButtonEvent) => {
        event.stopPropagation()
        onClickHandler(() => {
            return tokenApi('/rejectVoucher', {
                voucherId: item.objectId.voucherId
            })
        })
    }
    const onOpenSplitTicket = async (event: ButtonEvent) => {
        event.stopPropagation()
        setShowLoading(true)
        try {
            const { drawId, ticketNumber } = item.objectId
            const draw: Draw = await getDrawsByDrawId(item.objectId.drawId)
            const ticket: Ticket = await selectTicketByTicketNumber(ticketNumber as number, localGet('authAddress'), drawId)
            const images = await getAllImages()
            draw.drawImage = images[draw.drawImage]
            setIsBuySplitTicketOpen(true)
            setDraw(draw)
            setTicket(ticket)
            setInfo(item.objectId)
        } catch (error) {
            showToastMessage('Unknown Error.')
        }
        finally {
            setShowLoading(false)
        }
    }
    const onRejectSplitTicket = (event: ButtonEvent) => {
        event.stopPropagation()
        const { drawId, ticketNumber, organizerId } = item.objectId
        onClickHandler(async () => {
            // const ticket: Ticket = await selectTicketByTicketNumber(ticketNumber as number, localGet('authAddress'), drawId)
            /* return tokenApi('/splitTicket/reject', {
                ticketId: ticket._id,
                paymentMethod: "money",
            }) */
            return tokenApi('/splitTicket/reject', {
                organizerId,
                drawId,
                ticketNumber,
                paymentMethod: "money",
                amount: 0
            })
        })
    }
    const onClickRejoin = () => {
        setGlobalState("isClickRing", false)
    }
    const onGetGems = (event: ButtonEvent) => {
        event.stopPropagation()
        onClickHandler(() => {
            return tokenApi('/getGems', {
                questHistoryId: item.objectId.questsHistoryId,
                type: item.objectId.type
            })
        })
    }
    switch (item.type) {
        case NotificationMessageType.voucher:
            return <div className={style.notification_button_group} >
                <button disabled={!item.isNotify} onClick={onAcceptVoucher} type="button" className={style.notification_left_btn}>
                    Accept
                </button>
                <button disabled={!item.isNotify} onClick={onRejectVoucher} type="button" className={style.notification_right_btn}>
                    Reject
                </button>
            </div>
        case NotificationMessageType.invite_group_chat:
            return <div className={style.notification_button_group} >
                <button disabled={!item.isNotify} onClick={onAcceptInvite} type="button" className={style.notification_left_btn}>
                    Accept
                </button>
                <button disabled={!item.isNotify} onClick={onRejectInvite} type="button" className={style.notification_right_btn}>
                    Reject
                </button>
            </div>
        case NotificationMessageType.invite_new_friend:
            return <div className={style.notification_button_group} >
                <button disabled={!item.isNotify} onClick={onAcceptInvite} type="button" className={style.notification_left_btn}>
                    Accept
                </button>
                <button disabled={!item.isNotify} onClick={onRejectInvite} type="button" className={style.notification_right_btn}>
                    Reject
                </button>
            </div>
        case NotificationMessageType.invite_split_ticket:
            return <div className={style.notification_button_group} >
                <button disabled={!item.isNotify} onClick={onOpenSplitTicket} type="button" className={style.notification_left_btn}>
                    Accept
                </button>
                <button disabled={!item.isNotify} onClick={onRejectSplitTicket} type="button" className={style.notification_right_btn}>
                    Reject
                </button>
            </div>
        case NotificationMessageType.win_draw:
            return <div className={style.notification_button_group} >
                <button disabled={!item.isNotify} onClick={onClickRejoin} type="button" className={style.notification_left_btn}>
                    Rejoin
                </button>
            </div>
        case NotificationMessageType.lost_draw:
            return <></>
        case NotificationMessageType.daily_quests:
            return <div className={style.notification_button_group} >
                <button disabled={!item.isNotify} onClick={onGetGems} type="button" className={style.notification_left_btn}>
                    {item.objectId.gems} gems
                </button>
            </div>
    }
}
export type ButtonEvent = React.MouseEvent<HTMLButtonElement>
const Notification: React.FC<NotificationProps> = ({ list, getList, setNotify, isNoMore }) => {

    const [, setPageConfig] = useState<PageConfig>({ pageNumber: 1, pageSize: 10 })
    const [isBuySplitTicketOpen, setIsBuySplitTicketOpen] = useState<boolean>(false)
    const [draw, setDraw] = useState<Draw>()
    const [ticket, setTicket] = useState<Ticket>()
    const [info, setInfo] = useState<AdditionalInfo>()
    const fetchData = () => {
        setPageConfig(prev => {
            if (isNoMore) return { ...prev }
            const newPageConfig = { ...prev, pageNumber: prev.pageNumber + 1 }
            getList(newPageConfig)
            return newPageConfig
        })
    };
    const onReadAll = async () => {
        setShowLoading(true)
        await tokenApi('/readNotification', {
            read_ids: list.filter(item => !item.isRead).map(_ => _._id),
            isAll: true
        })
        setShowLoading(false)
    }

    const NotificationItem = ({ item }: { item: NotificationInfo }) => {
        const onRead = (_id: string, isRead: boolean) => {
            if (isRead) return;
            return async () => {
                await tokenApi('/readNotification', {
                    read_ids: [_id],
                    isAll: false
                });
            };
        };


        const NotificationAvatar = () => {
            let avatarSource = null;
            switch (item.type) {
                case NotificationMessageType.daily_quests:
                    avatarSource = <GemsSVG />;
                    break;
                case NotificationMessageType.invite_group_chat:
                    avatarSource = item.image !== null && item.image !== '' ? (
                        <img src={item.image} alt={item.message} />
                    ) : (
                        <img src={avatarGroup} alt={item.message} />
                    );
                    break;
                default:
                    avatarSource = <img src={item.image ?? DrawAvatar} alt={item.message} />;
            }
            return avatarSource;
        }
        return (
            <li key={item._id} onClick={onRead(item._id, item.isRead)}>
                <div className={style.notification_content}>
                    <div className={style.notification_read}>
                        <p style={{ display: item.isRead ? 'none' : 'block' }}></p>
                    </div>
                    <IonAvatar className={style.notification_avatar}>
                        <NotificationAvatar />
                    </IonAvatar>
                    <div className={style.notification_right}>
                        <p>{item.message}</p>
                        <NotificationButton item={item} setDraw={setDraw} setInfo={setInfo} setIsBuySplitTicketOpen={setIsBuySplitTicketOpen} setNotify={setNotify} setTicket={setTicket} />
                        <div className={style.notification_bottom}>
                            <span>{formatDate(new Date(item.createdTime))}</span>
                        </div>
                    </div>
                </div>
                <div className={style.notification_line}></div>
            </li>
        );
    };
    const onClosePage = () => {
        setIsBuySplitTicketOpen(false)
    }
    const onCancelConfrim = () => {
        setIsBuySplitTicketOpen(false)
    }
    return (
        <div className={style.notification_container}>
            <div className={style.notification_text}>
                <span>Notification</span>
                <span onClick={onReadAll}>Mark all as read</span>
            </div>
            <IonContent>
                <ul className={style.notification}>
                    {list.map((item: NotificationInfo) => (
                        <NotificationItem key={item._id} item={item} />
                    ))}
                </ul>
                <NoMore isShow={isNoMore} />
                <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                        fetchData();
                        setTimeout(() => ev.target.complete(), 500);
                    }}
                >
                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
            {draw && ticket && isBuySplitTicketOpen && info && <BuySplitTicket cancel={onCancelConfrim} close={onClosePage} info={info} draw={draw} ticket={ticket} isOpen={isBuySplitTicketOpen} />}
            {/* <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                message={'Please wait...'}
                duration={10000000}
                mode="ios"
                spinner="crescent"
            /> */}
        </div>

    )
}
const MAX_INVITER_LENGTH = 3
const amountFormatting = (amount: number) => window.innerWidth <= 340 && amount >= 100000000 ? formatPrize(amount) : amount.toLocaleString('en-US')
const getMinLess = (minNumber: number) => (arr: Inviter[]) => arr.slice(0, Math.min(minNumber, arr.length))
const getTwoLess = getMinLess(2)
interface BuySplitTicketProps {
    draw: Draw,
    ticket: Ticket,
    isOpen: boolean,
    info: Pick<AdditionalInfo, 'organizerId' | 'ticketNumber' | 'drawId'>
    close: () => void
    cancel: () => void
}
export const BuySplitTicket: FC<BuySplitTicketProps> = ({ draw, ticket, isOpen, info, close, cancel }) => {
    const [isBuySplitTicketSuccess, setIsBuySplitTicketSuccess] = useState<boolean>(false)
    const [isOpenPinCode, setIsOpenPinCode] = useState<boolean>(false)
    const inviters = [ticket.organizer, ...getTwoLess(ticket.invitees).filter(_ => _.state === InviterState.Activate)]
    const isOverMax = inviters && inviters.length > MAX_INVITER_LENGTH
    const onAcceptBuySplitTicket = async () => {
        const { organizerId, drawId, ticketNumber } = info
        try {
            setShowLoading(true)
            const { code, detail } = await tokenApi('/splitTicket/accept', {
                organizerId,
                drawId,
                ticketNumber,
                paymentMethod: "money",
                amount: ticket.splitTicketPrice
            })
            /* const { code, detail } = await tokenApi('/splitTicket/accept', {
                ticketId: ticket._id,
                paymentMethod: "money",
            }) */
            switch (Number(code)) {
                case 200:
                    // setNotify(item._id)
                    setIsBuySplitTicketSuccess(true)
                    break;
                default:
                    close()
                    showToastMessage(detail ?? 'Unknown Error.')
                    break;
            }
        }
        catch (error) {
        }
        finally {
            setIsOpenPinCode(false)
            setShowLoading(false)

        }
    }
    const onOpenPinCode = () => {
        setIsOpenPinCode(true)
    }
    const onClosePage = () => {
        setIsBuySplitTicketSuccess(false)
        close()
    }
    const onCancelConfirm = () => {
        cancel()
    }
    return (
        <IonModal className={style.buy_split_ticket} onDidDismiss={onCancelConfirm} isOpen={isOpen} initialBreakpoint={0.6} breakpoints={[0, 0.6]}>
            <IonContent className={style.split_wrapper}>
                <div className={style.split}>
                    <div className={style.split_header}>
                        <h5>Buy Split Ticket</h5>
                        <span>How it works</span>
                    </div>
                    <div className={style.split_card}>
                        <div className={style.card_image}>
                            <img src={draw.drawImage || img1} alt={draw.drawName} />
                        </div>
                        <div className={style.card_info}>
                            <span>Win with ${amountFormatting(ticket.splitTicketPrice)}</span>
                            <h5>${amountFormatting(ticket.prize)}</h5>
                            <div className={style.progrebox}>
                                <div style={{ width: `${draw.ticketsSold / draw.maxTickets * 100}%` }}
                                    className={style.progress_line}>
                                </div>
                            </div>
                            <div className={style.card_share}>
                                <ul>
                                    {inviters.map((inviter) => (
                                        <li key={inviter._id}>
                                            <img src={inviter.avatarURL} alt={inviter.nickname} />
                                        </li>
                                    ))}
                                </ul>
                                <span>{inviters.map(_ => _.nickname).join(' & ')}{isOverMax && '...'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.split_prize}>
                        <div className={style.beting}>
                            <p className={style.fontsize1}>Betting</p>
                            <p className={style.fontsize2}>${amountFormatting(ticket.splitTicketPrice)}</p>
                        </div>
                        <div className={style.svg}>
                            <RightArrowSVG />
                        </div>
                        <div className={style.winning}>
                            <p className={style.fontsize1}>Share Winning</p>
                            <p className={style.fontsize2}>${amountFormatting(ticket.splitTicketPrize)}</p>
                        </div>
                    </div>
                    <Button onClick={onOpenPinCode} style={{ backgroundColor: '#9C94FF', color: "#000" }}>Confirm</Button>
                </div>
            </IonContent>
            {draw && ticket && isBuySplitTicketSuccess && <BuyTicketSuccess close={onClosePage} draw={draw} ticket={ticket} isOpen={isBuySplitTicketSuccess} />}
            {isOpenPinCode && <PinCode
                openAuth0Modal={isOpenPinCode}
                setOpenAuth0Modal={setIsOpenPinCode}
                buyFunc={onAcceptBuySplitTicket} />}
            {/* <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                message={'Please wait...'}
                duration={10000000}
                mode="ios"
                spinner="crescent"
            /> */}
        </IonModal>
    )
}
interface BuyTicketSuccessProps {
    isOpen: boolean
    draw: Draw,
    ticket: Ticket,
    close: () => void
}
export const BuyTicketSuccess: FC<BuyTicketSuccessProps> = ({ isOpen, draw, ticket, close }) => {
    const inviters = [ticket.organizer, ...getTwoLess(ticket.invitees).filter(_ => _.state === InviterState.Activate)]
    const isOverMax = inviters && inviters.length > MAX_INVITER_LENGTH
    const onClosePage = () => {
        close()
    }
    return (
        <IonModal className={style.buy_ticket_success} isOpen={isOpen}>
            <IonHeader>
                <div className={style.share_header}>
                    <CloseSVG onClick={onClosePage} />
                </div>
            </IonHeader>
            <IonContent className='ion-padding'>
                <div className={style.share_container}>
                    <div className={style.share}>
                        <div className={style.share_success}>
                            <SuccessSVG />
                            <p>This is your ticket, thank you for joining!</p>
                        </div>
                        <div className={style.share_background}>
                            <div className={style.progress}>
                                <p>Split Ticket ({ticket.ticketPercentage})</p>
                            </div>
                            <div className={style.share_wrapper}>
                                <img src={draw.drawImage || img1} alt={draw.drawName} />
                                <div className={style.circle_line}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className={style.jackpot}>
                                    <span>Jackpot</span>
                                    <p>{ticketPrize(ticket.prize)}</p>
                                </div>
                            </div>
                            <div className={style.ticket_bottom}>
                                <p className={style.ticket_number}>Ticket Number <span>#{ticket.ticketNumber}</span></p>
                                <div className={style.ticket_share}>
                                    <ul>
                                        {inviters.map(inviter => (
                                            <li key={inviter._id}>
                                                <img src={inviter.avatarURL} alt={inviter.nickname} />
                                            </li>
                                        ))}
                                    </ul>
                                    <p className={style.ticket_share_txt}>share with <span>{inviters.map(_ => _.nickname).join(' & ')}</span>{isOverMax && '...'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button onClick={onClosePage} style={{ backgroundColor: '#9C94FF', color: "#000" }}>Done</Button>
                </div>
            </IonContent>
        </IonModal>
    )
}
export type SVGProps = React.SVGProps<SVGSVGElement>;
const SuccessSVG: FC<SVGProps> = (props) => (
    <svg {...props} width="64" height="64" viewBox="0 0 64 64" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M32 64C23.4525 64 15.4166 60.6714 9.37263 54.6275C3.3285 48.5834 0 40.5475 0 32C0 23.4525 3.3285 15.4166 9.37263 9.3725C15.4166 3.32863 23.4525 0 32 0C40.5475 0 48.5834 3.32863 54.6274 9.3725C60.6715 15.4166 64 23.4525 64 32C64 40.5475 60.6715 48.5834 54.6274 54.6275C48.5834 60.6714 40.5475 64 32 64ZM32 2.75C15.8715 2.75 2.75 15.8715 2.75 32C2.75 48.1285 15.8715 61.25 32 61.25C48.1285 61.25 61.25 48.1285 61.25 32C61.25 15.8715 48.1285 2.75 32 2.75Z"
            fill="#CCCCCC" />
        <path
            d="M27.4067 41.3993C27.0564 41.3993 26.7062 41.2664 26.438 41.0003L16.5047 31.1409C15.9657 30.6059 15.9624 29.7353 16.4974 29.1963C17.0323 28.6574 17.9028 28.654 18.4419 29.1889L27.403 38.0833L44.3705 21.1158C44.9075 20.5789 45.7782 20.5789 46.3152 21.1158C46.852 21.6528 46.852 22.5234 46.3152 23.0604L28.379 40.9967C28.1105 41.265 27.7587 41.3993 27.4067 41.3993Z"
            fill="#9C94FF" />
    </svg>
)
const CloseSVG: FC<SVGProps> = (props) => (
    <svg  {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
    </svg>
)
const RightArrowSVG: FC<SVGProps> = (props) => (
    <svg {...props} width="12" height="12" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
            fill="#A6A9B8" />
    </svg>
)
const GemsSVG: FC<SVGProps> = (props) => (<svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3262_8311)">
        <mask id="mask0_3262_8311" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
            <path d="M48 0H0V48H48V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3262_8311)">
            <path d="M15.4004 15.969H27.2006C27.3312 15.969 27.46 16.008 27.5764 16.0829C27.6928 16.1578 27.7935 16.2665 27.8703 16.4L31.0351 21.8985C31.0914 21.9963 31.1191 22.1157 31.1136 22.2365C31.108 22.3572 31.0694 22.4718 31.0046 22.5606L21.6043 35.4292C21.5655 35.4821 21.5185 35.5243 21.4663 35.553C21.414 35.582 21.3575 35.5968 21.3005 35.5968C21.2434 35.5968 21.187 35.582 21.1347 35.553C21.0824 35.5243 21.0354 35.4821 20.9966 35.4292L11.5964 22.5617C11.5313 22.4727 11.4926 22.358 11.487 22.237C11.4814 22.116 11.5093 21.9964 11.5658 21.8985L14.7306 16.4C14.8074 16.2665 14.9081 16.1578 15.0246 16.0829C15.141 16.008 15.2697 15.969 15.4004 15.969Z" stroke="#F9FFFF" strokeWidth="2" strokeLinejoin="round" />
            <path d="M28.3125 33.8845L36.5139 22.4027L33.6196 17.6206" stroke="#F9FFFF" strokeWidth="2" strokeLinejoin="round" />
        </g>
    </g>
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#FAFFFF" />
    <defs>
        <clipPath id="clip0_3262_8311">
            <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
    </defs>
</svg>
)
const NoMore = ({ isShow }: { isShow: boolean }) => {

    return (<>
        {isShow ? <p className={style.no_more}>No More</p> : ''}
    </>)
}
export default Notification