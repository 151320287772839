


import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { getDrawRecord, getWonPendingDrawsCount } from "../../../db/schema/draws";
import ill from "../../../image/account/ill.png";
import style from "../../../style/Account.module.css";
import { addCommas } from "../../../utils/addCommas";
import { formatDate } from "../../../utils/formatDate";
import { localGet } from "../../../utils/localStorage";

import { selectTicketByDrawId } from "db/schema/tickets";
import { setShowLoading } from "../../../components/GlobalLoading";
import { getAllImages } from "../../../db/schema/images";
import back from "../../../image/upcoming/1.png";
import { useStore } from "../../../store";

const DrawRecordModal: React.FC = () => {
    const [drawImages, setDrawImages] = useState<any>()
    useEffect(() => {
        const setimages = async () => {
            setDrawImages(await getAllImages())
        }
        setimages()
        //eslint-disable-next-line
    }, [localGet('images')])
    const { isGameRecordModalOpen, changeModalState } = useStore();

    const [showType, setShowType] = useState('All') //Won,Pending

    const [drawCounts, setDrawCounts] = useState<any>({})

    const [allList, setAllList]: any = useState([])
    const [allListPageNumber, setAllListPageNumber] = useState<number>(1)
    const [wondrawslist, setWonDrawsList]: any = useState([])
    const [wonListPageNumber, setWonListPageNumber] = useState<number>(1)
    const [tobedrawnlist, setTobedrawnList]: any = useState([])
    const [tobedrawnListPageNumber, setTobedrawnListPageNumber] = useState<number>(1)

    const getAllList = async (pageNumber: Number) => {
        const counts = await getWonPendingDrawsCount()
        console.log('ssss', counts);

        setDrawCounts(counts)
        const all = await getDrawRecord({
            isJoined: true
        }, 10, pageNumber)
        // const formatAll = all.map((item: any) => {
        //     const newData = { ...item._data, drawImage: item._data.drawImage === '' ? back : item._data.drawImage }
        //     return newData
        // })
        console.log('ssss', all);

        setAllList(all)
    }

    const getWondrawslist = async (pageNumber: Number) => {
        const won = await getDrawRecord({ isJoined: true, winnerWallet: { $ne: '' } }, 10, pageNumber);
        // const formatWon = won.map((item: any) => {
        //     const newData = { ...item._data, drawImage: item._data.drawImage === '' ? back : item._data.drawImage }
        //     return newData
        // })
        setWonDrawsList(won)
    }

    const getTobedrawnlist = async (pageNumber: Number) => {
        const tobedrawn = await getDrawRecord({ isJoined: true, winnerWallet: '' }, 10, pageNumber);
        // const formatTobedrawn = tobedrawn.map((item: any) => {
        //     const newData = { ...item._data, drawImage: item._data.drawImage === '' ? back : item._data.drawImage }
        //     return newData
        // })
        setTobedrawnList(tobedrawn)
    }

    const generateItems = async () => {

        if (showType === 'All') {
            const result = await getDrawRecord({ isJoined: true }, 10, allListPageNumber + 1);
            if (result.length !== 0) {
                // const formatAll = result.map((item: any) => {
                //     const newData = { ...item._data, drawImage: item._data.drawImage === '' ? back : item._data.drawImage }
                //     return newData
                // })
                setAllList([...allList, ...result])
                setAllListPageNumber(allListPageNumber + 1)
            }
        }

        if (showType === 'Won') {
            const result = await getDrawRecord({ isJoined: true, winnerWallet: localGet('authAddress') }, 10, wonListPageNumber + 1);
            if (result.length !== 0) {
                // const formatAll = result.map((item: any) => {
                //     const newData = { ...item._data, drawImage: item._data.drawImage === '' ? back : item._data.drawImage }
                //     return newData
                // })
                setWonDrawsList([...wondrawslist, ...result])
                setWonListPageNumber(wonListPageNumber + 1)
            }
        }

        if (showType === 'Pending') {
            const result = await getDrawRecord({ isJoined: true, winnerWallet: '' }, 10, tobedrawnListPageNumber + 1);
            if (result.length !== 0) {
                // const formatAll = result.map((item: any) => {
                //     const newData = { ...item._data, drawImage: item._data.drawImage === '' ? back : item._data.drawImage }
                //     return newData
                // })
                setTobedrawnList([...tobedrawnlist, ...result])
                setTobedrawnListPageNumber(tobedrawnListPageNumber + 1)
            }
        }
    }

    const AllItem: React.FC<{ item: any }> = ({ item }) => {
        const [datas, setDatas] = useState<any>(item)
        useEffect(() => {
            if (item.isSplitTicket) {
                let local = item
                selectTicketByDrawId(item.drawId).then(res => {
                    local['splitData'] = res
                    setDatas(local)
                })
            }
            //eslint-disable-next-line
        }, [])
        return (
            <li>
                <div className={style.gamerecord_ul_box}>
                    <div className={style.gamerecord_ul_img}>
                        <img src={datas?.drawImage === '' ? back : `${drawImages?.[datas?.drawImage] || back}`} alt="" />
                        <div className={style.gamerecord_ul_img_mod} style={datas?.winnerWallet === '' ? { display: 'block' } : { display: 'none' }}>
                            <p>Waiting...</p>
                        </div>
                    </div>
                    <div className={style.gamerecord_ul_text}>
                        <p>Join with ${addCommas(datas?.isSplitTicket ? datas?.splitData?.splitTicketPrice : datas?.ticketPrice)}</p>
                        <p>${addCommas(datas?.isSplitTicket ? datas?.splitData?.splitTicketPrize : datas?.prize)}</p>
                        <p>{formatDate(new Date(datas?.endTime))}</p>
                    </div>
                </div>
                {datas?.winnerWallet !== '' ?
                    (datas?.winnerWallet === localGet('authAddress') || (datas?.isSplitTicket && datas?.splitData?.isWinner) ?
                        <div className={style.gamerecord_winn}>
                            <span>+{addCommas(datas?.isSplitTicket ? datas?.splitData?.splitTicketPrize : datas?.prize / (datas.winnerMembers.length + 1))}</span>
                        </div>
                        :
                        <div className={style.gamerecord_defeat}>
                            <p>Missed the Draw</p>
                        </div>
                    )

                    :
                    <></>
                }
            </li>
        )
    }
    const WonItem: React.FC<{ item: any }> = ({ item }) => {
        const [datas, setDatas] = useState<any>(item)
        useEffect(() => {
            if (item?.isSplitTicket) {
                let local = item
                selectTicketByDrawId(item.drawId).then(res => {
                    local['splitData'] = res
                    setDatas(local)
                })
            }
            //eslint-disable-next-line
        }, [])
        return (
            <li style={{
                display: datas?.isSplitTicket ?
                    datas?.splitData?.isWinner === true ? 'flex' : 'none'
                    : 'flex'
            }}>
                <div className={style.gamerecord_ul_box}>
                    <div className={style.gamerecord_ul_img}>
                        <img src={datas?.drawImage === '' ? back : `${drawImages?.[datas?.drawImage] || back}`} alt="" />
                    </div>
                    <div className={style.gamerecord_ul_text}>
                        <p>Join with ${addCommas(datas?.isSplitTicket ? datas?.splitData?.splitTicketPrice : datas?.ticketPrice)}</p>
                        <p>${addCommas(datas?.isSplitTicket ? datas?.splitData?.splitTicketPrize : datas?.prize)}</p>
                        <p>{formatDate(new Date(datas?.endTime))}</p>
                    </div>
                </div>

                <div className={style.gamerecord_winn}>
                    <span>+{addCommas(datas?.isSplitTicket ? datas?.splitData?.splitTicketPrize : datas?.prize)}</span>
                </div>
            </li>
        )
    }
    return (
        <IonModal isOpen={isGameRecordModalOpen} mode="ios" className={style.gamerecordbox}
            onIonModalWillPresent={async () => {
                setShowLoading(true)
                await getAllList(1)
                getWondrawslist(1)
                getTobedrawnlist(1)
                setShowLoading(false)
            }}
            onDidDismiss={() => {
                changeModalState('isGameRecordModalOpen', false)
                setShowType('All')
                setAllListPageNumber(1)
                setWonListPageNumber(1)
                setTobedrawnListPageNumber(1)
            }}>
            <IonContent style={{ '--padding-top': '100px' }}>
                <div className={style.gamerecordbox_top}>
                    <div onClick={() => { changeModalState('isGameRecordModalOpen', false) }}>
                        <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                        </svg>
                    </div>
                    <span>Draw Record</span>
                    <div style={{ paddingRight: '16px' }}></div>

                </div>
                <IonRefresher slot="fixed" onIonRefresh={(e) => {
                    setAllListPageNumber(1)
                    setWonListPageNumber(1)
                    setTobedrawnListPageNumber(1)
                    setTimeout(() => {
                        getAllList(1)
                        getWondrawslist(1)
                        getTobedrawnlist(1)
                        e.detail.complete()
                    }, 1000);
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {allList.length > 0 ? <div className={style.gamerecord_tab}>
                    <div className={style.gamerecord_tab_p}>
                        <p onClick={() => {
                            // gets()
                            setShowType('All')
                        }}
                            className={showType === 'All' ? style.activation : ''}
                        >
                            All Draws ({drawCounts.all})
                        </p>
                        <p onClick={() => {
                            // gets()
                            // getWondrawslist()
                            setShowType('Won')
                        }}
                            className={showType === 'Won' ? style.activation : ''}
                        >
                            Won Draws({drawCounts.won})
                        </p>
                        <p onClick={() => {
                            setShowType('Pending')
                        }}
                            className={showType === 'Pending' ? style.activation : ''}
                        >
                            Pending Draws({drawCounts.pending})
                        </p>
                    </div>
                </div>
                    :
                    <div className={style.gamerecord_tab_noneimg}>
                        <img src={ill} alt="" />
                        <p>No Draw Record</p>
                    </div>
                }


                {/* All Draws list */}
                <ul className={style.gamerecord_ul} style={showType === 'All' ? { display: "flex" } : { display: 'none' }}>
                    {allList.map((item: any, index: any) =>
                        <AllItem item={item} key={index} />
                    )}
                </ul>

                {/* Won Draws list */}
                {wondrawslist.length > 0 ?
                    <ul className={style.gamerecord_ul} style={showType === 'Won' ? { display: "flex" } : { display: 'none' }}>
                        {wondrawslist.map((item: any, index: any) =>
                            <WonItem item={item} key={index} />
                        )}
                    </ul>
                    :
                    <div className={style.gamerecord_tab_noneimg} style={showType === 'Won' ? { display: "flex" } : { display: 'none' }}>
                        <img src={ill} alt="" />
                        <p>No Draw Record</p>
                    </div>
                }


                {/* To be drawn list */}
                {tobedrawnlist.length > 0 ? <ul className={style.gamerecord_ul} style={showType === 'Pending' ? { display: "flex" } : { display: 'none' }}>
                    {tobedrawnlist.map((item: any, index: any) => (
                        <li key={index}>
                            <div className={style.gamerecord_ul_box}>
                                <div className={style.gamerecord_ul_img}>
                                    <img src={item.drawImage === '' ? back : `${drawImages?.[item.drawImage] || back}`} alt="" />
                                    <div className={style.gamerecord_ul_img_mod} >
                                        <p>Waiting...</p>
                                    </div>
                                </div>
                                <div className={style.gamerecord_ul_text}>
                                    <p>Join with ${addCommas(item.ticketPrice)}</p>
                                    <p>${addCommas(item.prize)}</p>
                                    <p>{formatDate(new Date(item.startTime))}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                    :
                    <div className={style.gamerecord_tab_noneimg} style={showType === 'Pending' ? { display: "flex" } : { display: 'none' }}>
                        <img src={ill} alt="" />
                        <p>No Draw Record</p>
                    </div>
                }
                <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                        generateItems();
                        setTimeout(() => ev.target.complete(), 500);
                    }}
                >
                    <IonInfiniteScrollContent ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonModal>
    )
}
export default DrawRecordModal