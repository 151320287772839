import { Chat, useChatroom } from "@DataAssetStore/chatcomponent";
import { IonContent, IonModal } from "@ionic/react";
import { getRoomMembers } from "api/chat";
import { showToastMessage } from "components/GlobalLoading";
import GiftVoucherDetail from "components/giftComponent/GiftVocherDetail";
import GroupVoucherChoose from "components/giftComponent/GroupVouvherChoose";
import UserInfoModal from "pages/friends/components/userInfoModal";
import { FC, useEffect, useState } from "react";
import { useStore } from "store";
interface ChatModalProps {
    isOpen: boolean
    closeModal: (isGroup: boolean) => void
    openUserInfoModal?: () => void
}
const ChatModal: FC<ChatModalProps> = ({ closeModal, isOpen, openUserInfoModal }) => {
    const [historySearchShow, setHistorySearchShow] = useState<boolean>(false)
    const { changeModalState } = useStore()
    const [privateUserId, setCurrentPrivateUserId] = useState<string>('');
    const { currentChatroom,setCurrentChatroom } = useChatroom()
    const privateChatUserInfo = async () => {
        if (!currentChatroom || !currentChatroom.room_id) return;
        try {
            const data = await getRoomMembers(currentChatroom.room_id);
            if (data.length === 1) {
                setCurrentPrivateUserId(data[0].user_id!);
            }
        } catch (error: any) {
            showToastMessage(error.message);
        }
    };

    const onOpenUserInfoModal = () => {
        changeModalState('isUserInfoModalOpen', true)
    }
    const onCloseModal = () => {
        if (!currentChatroom) return
        closeModal(currentChatroom.isGroup)
        setCurrentChatroom(null)
    }
    const onDidDismiss = () => {
        onCloseSearch()
    }
    const onCloseSearch = () => {
        setHistorySearchShow(false)
    }
    const changeSearchState = (isOpen: boolean) => {
        setHistorySearchShow(isOpen)
        changeModalState('isUserInfoModalOpen', false)
        setTimeout(() => {
            document.getElementById('historySearch')?.focus()
        }, 800)
    }
    useEffect(() => {
        if (currentChatroom?.room_id) {
            if (!currentChatroom?.isGroup) {
                privateChatUserInfo();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChatroom]);
    const { REACT_APP_ENABLE_REPLY, REACT_APP_IS_SHOW_CHAT_DATE, REACT_APP_ENABLE_AI_MSG_DOWNLOAD, REACT_APP_ENABLE_AI_MSG_COPY, REACT_APP_ENABLE_COMMENT_EMOJI, REACT_APP_ENABLE_SEND_EMOJI, REACT_APP_ENABLE_UPLOAD_FILE, REACT_APP_ENABLE_COMPARE_FILE, REACT_APP_ENABLE_FILL_FORM } = process.env
    return (
        <IonModal isOpen={isOpen} className="personal-chat-modal" onDidDismiss={onDidDismiss}>
            <IonContent>
            <div style={{ height: '100%', overflow: 'hidden' }}>
            {currentChatroom && currentChatroom.room_id && <Chat
                    isShowAvatar
                    isUseReply={REACT_APP_ENABLE_REPLY === "true"}
                    isShowChatDate={REACT_APP_IS_SHOW_CHAT_DATE === "true"}
                    isUseAiMsgDownload={REACT_APP_ENABLE_AI_MSG_DOWNLOAD === "true"}
                    isUseAiMsgCopy={REACT_APP_ENABLE_AI_MSG_COPY === "true"}
                    isUseCommentEmoji={REACT_APP_ENABLE_COMMENT_EMOJI === "true"}
                    isUseSendEmoji={REACT_APP_ENABLE_SEND_EMOJI === "true"}
                    isUseUploadFile={REACT_APP_ENABLE_UPLOAD_FILE === "true"}
                    isUseCompareFile={REACT_APP_ENABLE_COMPARE_FILE === "true"}
                    isUseFillForm={REACT_APP_ENABLE_FILL_FORM === "true"}
                    room_id={currentChatroom.room_id}
                    setHistorySearchShow={onCloseSearch}
                    chatHistorySearchShow={historySearchShow}
                    onClickAddBtn={onOpenUserInfoModal}
                    onBack={onCloseModal}
                    targetId="historySearch"
                />}
                <UserInfoModal userId={privateUserId} changeSearchState={changeSearchState} />
                <GroupVoucherChoose />
                <GiftVoucherDetail />
            </div>
            </IonContent>
        </IonModal>
    )
}

export default ChatModal
